import React, { useRef } from "react";
import { Layout } from "../components";
import virtualTradeshowStyles from "./virtualTradeshow.module.scss";
import VirtualCarousel from "../components/VirtualTradeShow/VirtualCarousel";
import Button from "../components/Button";
import FeatureCard from "../components/FeatureCard";
import virtualIcon from "../../static/feature-virtual.svg";
import analyticsIcon from "../../static/feature-analytics.svg";
import embaddableIcon from "../../static/feature-embeddable.svg";
import landingIcon from "../../static/feature-landing-page.svg";
import powerfullIcon from "../../static/feature-powerfull.svg";
import enterpriseIcon from "../../static/feature-enterprise.svg";
import mobileIcon from "../../static/feature-mobile-ready.svg";
import blueTick from "../../static/mobile-tick.svg";
import customerBannerImage from "../../static/customer-banner-image.png";
import quote from "../../static/quote-gray.svg";
import ContactUsForm from "../components/ContactUs/ContactUsForm/ContactUsForm";
import { Helmet } from "react-helmet";

function virtualTradeshow(props) {
  const { location } = props;
  const url = location.href ? location.href : '';

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  const landingCardContent = [
    {
      id: 1,
      content: "Custom designed built for your brand and theme of your event",
      iconPath: "../../landing-tick.svg",
    },
    { id: 2, content: "Mobile-first", iconPath: "../../landing-tick.svg" },
    {
      id: 3,
      content: "Built on the highly scalable and modular architecture pattern",
      iconPath: "../../landing-tick.svg",
    },
    {
      id: 4,
      content: "Focused on driving traffic conversion for your event",
      iconPath: "../../landing-tick.svg",
    },
    {
      id: 5,
      content:
        "Branded email templates with direct interaction to all major email clients",
      iconPath: "../../landing-tick.svg",
    },
    {
      id: 6,
      content: "Personalize trade show experience with form data",
      iconPath: "../../landing-tick.svg",
    },
  ];
  const virtualCardContent = [
    {
      id: 1,
      content:
        "Work with our specialized 3D rendering partners to visualize a custom experience ground up or bring your own booth design from your past exhibits.",
      iconPath: "../../virtual-tick.svg",
    },
    {
      id: 2,
      content:
        "Choose a purpose-driven technology stack. Add pre-build capabilities like Document/Video/Link Interactions, Add to Bag, Contact form, Helpbot, Jump to Booth, and Responsive Navigation.",
      iconPath: "../../virtual-tick.svg",
    },
    {
      id: 3,
      content:
        "Add custom capabilities and interaction that best compliments the purpose of the event.",
      iconPath: "../../virtual-tick.svg",
    },
    {
      id: 4,
      content: "Integrate and launch into Zoom, ON24...etc.",
      iconPath: "../../virtual-tick.svg",
    },
  ];
  const powerfullCardContent = [
    {
      id: 1,
      content: "Customer onboarding solutions - CVET.",
      iconPath: "../../powerfull-tick.svg",
    },
    {
      id: 2,
      content: "Virtual meeting and session - Zoom, GoTo.",
      iconPath: "../../powerfull-tick.svg",
    },
    {
      id: 3,
      content: "Email - SendGrid",
      iconPath: "../../powerfull-tick.svg",
    },
    {
      id: 4,
      content: "Video Hosting - Azure Media Library WISTIA, Youtube...etc.",
      iconPath: "../../powerfull-tick.svg",
    },
  ];
  const analyticsCardContent = [
    {
      id: 1,
      content:
        "Measure more than vanity metrics with our proprietary customer journey analytics.",
      iconPath: "../../analytics-tick.svg",
    },
    {
      id: 2,
      content:
        "Create intelligent lead segments based on micro  engagement data.",
      iconPath: "../../analytics-tick.svg",
    },
    {
      id: 3,
      content: "Monitor real engagement across the experience and content.",
      iconPath: "../../analytics-tick.svg",
    },
    {
      id: 4,
      content: "Personalize experience based on user segments.",
      iconPath: "../../analytics-tick.svg",
    },
  ];
  const enterpriseCardContent = [
    {
      id: 1,
      content:
        "Your data will be hosted and managed on Azure or Google Cloud or your enterprise infrastructure by our Certified Cloud Architects.",
      iconPath: "../../enterprise-tick.svg",
    },
  ];
  const embeddableCardContent = [
    {
      id: 1,
      content:
        "Convert your 2D images, guides, and Brochures to immersive content.",
      iconPath: "../../embed-tick.svg",
    },
  ];
  const mobileCardContent = [
    {
      id: 1,
      content:
        "Every experience developed is mobile-ready and can be released as an App.",
      iconPath: "../../mobile-tick.svg",
    },
  ];

  const techServiceListData = [
    {
      id: 1,
      content: "Landing Page - React/Vue.js",
    },
    {
      id: 2,
      content: "3D Model - Three.js/UNITY",
    },
    {
      id: 3,
      content: "CDN for Performance",
    },
    {
      id: 4,
      content: "Azure Storage Bucket or Similar",
    },
    {
      id: 5,
      content: "Azure Email Service or Similar",
    },
    {
      id: 6,
      content: "Azure Cosmos DB/MySQL Cloud Service or Similar",
    },
    {
      id: 7,
      content: "API: Azure Functions or Similar",
    },
    {
      id: 8,
      content: "Data Visualisation: PowerBI, Tableau or Similar",
    },
  ];
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Best Online Virtual Trade show - Virtual Product Exhibits - Hashout
        </title>
        <meta name="keyword" content="Virtual Tradeshow, Virtual Tradeshow booth, Virtual Product Exhibits, Online Virtual Tradeshow, Virtual Tradeshow Companies, Virtual Trade Fairs, Online Trade Show, Virtual event platform, Virtual Exhibit platform, Virtual event organiser"></meta>
        <meta
          name="description"
          content="Looking for the best way to conduct a Virtual Trade Show fair? Virtual Trade Show Exhibit is the best online trade show exhibit to achieve 3x conversion for any business and get more leads by showcasing your products in an interactive way. Virtual Trade Show features include: 3D designs, live chat, e-commerce and social media integration, intuitive navigation and analytics."
        ></meta>
      </Helmet>
      <div className={virtualTradeshowStyles.carouselContainer}>
        <div className={virtualTradeshowStyles.heroSectionWrapper}>
          <div className={virtualTradeshowStyles.heroBannerImage}>
            <div className={virtualTradeshowStyles.heroBannerImageWrapper}>
              <img src="../../virtual-hero-banner.svg" />
            </div>
          </div>
          <div className={virtualTradeshowStyles.virtualCarouselWrapper}>
            <VirtualCarousel
              showIndicator={true}
              autoPlay={true}
              className={"virtualCarousel banner"}
            >
              <div className={virtualTradeshowStyles.heroBannerWrapper}>
                <div className={virtualTradeshowStyles.bannerInfoWrapper}>
                  <div className={virtualTradeshowStyles.infoBold}>
                    <span>
                      Achieve{" "}
                      <span className={virtualTradeshowStyles.boldRed}>
                        3X conversion
                      </span>{" "}
                      on your digital marketing campaigns with{" "}
                      <span className={virtualTradeshowStyles.boldRed}>
                        Virtual Product Exhibits
                      </span>
                    </span>
                  </div>
                  <div className={virtualTradeshowStyles.infoRegular}>
                    <span>
                      Convert the boring 2D images and product guides in your
                      product marketing site into immersive 3D product guides
                      that make learning about your product fun and engaging.
                    </span>
                  </div>
                </div>
              </div>
              <div className={virtualTradeshowStyles.heroBannerWrapper}>
                <div className={virtualTradeshowStyles.bannerInfoWrapper}>
                  <div className={virtualTradeshowStyles.infoBold}>
                    <span>
                      Achieve{" "}
                      <span className={virtualTradeshowStyles.boldRed}>
                        2X uplift
                      </span>{" "}
                      in education event attendance with{" "}
                      <span className={virtualTradeshowStyles.boldRed}>
                        Augmented Reality (AR)
                      </span>
                    </span>
                  </div>
                  <div className={virtualTradeshowStyles.infoRegular}>
                    <span>
                      Give a 3D twist to your education event with an immersive
                      virtual conference setting with all the attributes of a
                      real conference venue.
                    </span>
                  </div>
                </div>
              </div>
              <div className={virtualTradeshowStyles.heroBannerWrapper}>
                <div className={virtualTradeshowStyles.bannerInfoWrapper}>
                  <div className={virtualTradeshowStyles.infoBold}>
                    <span>
                      Generate{" "}
                      <span className={virtualTradeshowStyles.boldRed}>
                        3X More leads
                      </span>{" "}
                      by switching to{" "}
                      <span className={virtualTradeshowStyles.boldRed}>
                        Virtual Product Exhibits
                      </span>
                    </span>
                  </div>
                  <div className={virtualTradeshowStyles.infoRegular}>
                    <span>
                      Resume your Face to Face marketing program with a virtual
                      spin by taking your famous and familiar trade show booth
                      digital.
                    </span>
                  </div>
                </div>
              </div>
              <div className={virtualTradeshowStyles.heroBannerWrapper}>
                <div className={virtualTradeshowStyles.bannerInfoWrapper}>
                  <div className={virtualTradeshowStyles.infoBold}>
                    <span>
                      Looking for a trusted partner to kick start your first{" "}
                      <span className={virtualTradeshowStyles.boldRed}></span>{" "}
                      <span className={virtualTradeshowStyles.boldRed}>
                        Virtual Trade Show
                      </span>
                    </span>
                  </div>
                  <div className={virtualTradeshowStyles.infoRegular}>
                    <span>
                      Adopt to the new normal and meet your marketing goals with
                      our deep customer empathy and advanced technology!
                    </span>
                  </div>
                </div>
              </div>
              <div className={virtualTradeshowStyles.heroBannerWrapper}>
                <div className={virtualTradeshowStyles.bannerInfoWrapper}>
                  <div className={virtualTradeshowStyles.infoBold}>
                    <span>
                      Are you an{" "}
                      <span className={virtualTradeshowStyles.boldRed}>
                        exhibit event
                      </span>{" "}
                      production company looking for a{" "}
                      <span className={virtualTradeshowStyles.boldRed}>
                        technology partner
                      </span>
                    </span>
                  </div>
                  <div className={virtualTradeshowStyles.infoRegular}>
                    <span>
                      Expand your delivery capability with our technology
                      expertise, cost-effective, and highly professional
                      delivery model.
                    </span>
                  </div>
                </div>
              </div>
            </VirtualCarousel>
          </div>
        </div>
        <div className={virtualTradeshowStyles.showcaseIconWrapper}>
          <span className={virtualTradeshowStyles.iconWrapper}>
            <img src="../../straumann-icon.svg" />
          </span>
          <span className={virtualTradeshowStyles.iconWrapper}>
            <img src="../../clearcorrect-icon.svg" />
          </span>
          <span className={virtualTradeshowStyles.iconWrapper}>
            <img src="../../neodent-icon.svg" />
          </span>
          <span className={virtualTradeshowStyles.iconWrapper}>
            <img src="../../insulet-icon.svg" />
          </span>
        </div>
        {/* <div className={virtualTradeshowStyles.carouselButtonContainer}>
          <Button
            buttonClass="primary"
            buttonText="request for demo"
            clickFunction={executeScroll}
          />
        </div> */}
      </div>
      <div className={virtualTradeshowStyles.videoSection}>
        <div className={virtualTradeshowStyles.videoContainer}>
          {/* <div className={virtualTradeshowStyles.videoWrapper}>
            <iframe
              width="570"
              height="315"
              src="https://www.youtube.com/embed/t1XCzWlYWeA"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div> */}
          <div className={virtualTradeshowStyles.videoInfo}>
            <h2 className={virtualTradeshowStyles.videoTitle}>
              Virtual-The New normal!
            </h2>
            <p className={virtualTradeshowStyles.videoDescription}>
              Elevate your marketing game and beat the goals with immersive
              3D-based virtual experiences across your complete product
              marketing and support landscape. With all the tools, technologies,
              and client device capabilities available to deliver an incredible
              digital experience, now is the time to adapt to NEW NORMAL.
            </p>
            <Button
              buttonClass="primary"
              buttonText="request for demo"
              clickFunction={executeScroll}
            />
          </div>
          <div className={virtualTradeshowStyles.divider}></div>
        </div>
      </div>
      <div className={virtualTradeshowStyles.featureCardWrapper}>
        <div className={virtualTradeshowStyles.featureSectionLeft}>
          <div className={virtualTradeshowStyles.featureSectionInfo}>
            <h2 className={virtualTradeshowStyles.featureTitle}>Features</h2>
            <p className={virtualTradeshowStyles.featureDescription}>
              Get Immersive virtual conference setting with all the attributes
              of a real conference venue under one platform.
            </p>
            <Button
              buttonClass="primary"
              buttonText="request for demo"
              clickFunction={executeScroll}
            />
          </div>
          <FeatureCard
            cardData={virtualCardContent}
            imagePath={virtualIcon}
            imageName="Virtual Experience Icon"
            cardTitle="Virtual Experience"
          />
          <FeatureCard
            cardData={analyticsCardContent}
            imagePath={analyticsIcon}
            imageName="Analytics Icon"
            cardTitle="Analytics"
          />
          <FeatureCard
            cardData={embeddableCardContent}
            imagePath={embaddableIcon}
            imageName="Embaddable Icon"
            cardTitle="Embeddable 3D renders for the Web"
          />
        </div>
        <div className={virtualTradeshowStyles.featureSectionRight}>
          <FeatureCard
            cardData={landingCardContent}
            imagePath={landingIcon}
            imageName="Landing page Icon"
            cardTitle="Landing Page and Onboarding"
          />
          <FeatureCard
            cardData={powerfullCardContent}
            imagePath={powerfullIcon}
            imageName="Powerfull Icon"
            cardTitle="Powerful integrations to ensure the best of all the segments"
          />
          <FeatureCard
            cardData={enterpriseCardContent}
            imagePath={enterpriseIcon}
            imageName="Enterprise Icon"
            cardTitle="Enterprise-Grade Hosting, Scaling, and Security"
          />
          <FeatureCard
            cardData={mobileCardContent}
            imagePath={mobileIcon}
            imageName="Mobile Ready Icon"
            cardTitle="Mobile Ready"
          />
        </div>
      </div>
      <div className={virtualTradeshowStyles.technologyStackSection}>
        <div className={virtualTradeshowStyles.titleSection}>
          <h2 className={virtualTradeshowStyles.title}>Our Technology Stack</h2>
          <p className={virtualTradeshowStyles.description}>
            We rely on a modern and current tech stack to build your industry
            compliant virtual experience
          </p>
        </div>
        <div className={virtualTradeshowStyles.techContent}>
          <div className={virtualTradeshowStyles.techBannerImage}>
            <img src="../../tech-banner.svg" />
          </div>
          <div className={virtualTradeshowStyles.techServiceList}>
            <ul>
              {techServiceListData.map((list) => (
                <li key={list.id}>
                  <span>
                    <img src={blueTick} />
                  </span>
                  {list.content}
                </li>
              ))}
            </ul>
            <Button
              buttonClass="primary"
              buttonText="request for demo"
              clickFunction={executeScroll}
            />
          </div>
        </div>
      </div>
      <div className={virtualTradeshowStyles.offeringSection}>
        <div className={virtualTradeshowStyles.offerDetails}>
          <h2 className={virtualTradeshowStyles.offerTitle}>Our offerings</h2>
          <p className={virtualTradeshowStyles.offerInfo}>
            With every signed project you get to work with a dedicated team
            consisting of a UX Designer, Graphic Designer, Project Manager,
            Developer and Tester.
          </p>
          <p className={virtualTradeshowStyles.offerInfo}>
            We have built award-winning 3D virtual booths with all our OOB
            capabilities including hosting for a year for as low as 9500 USD.
          </p>
          <Button
            buttonClass="secondary"
            buttonText="Contact us"
            clickFunction={executeScroll}
          />
          <div className={virtualTradeshowStyles.curvedLinesTop}></div>
          <div className={virtualTradeshowStyles.curvedLinesBottom}></div>
        </div>
        <div className={virtualTradeshowStyles.offerBannerImage}>
          {/* <img src={offerBanner} /> */}
        </div>
      </div>
      <div className={virtualTradeshowStyles.customerCarousel}>
        <div className={virtualTradeshowStyles.customerCarouselWrapper}>
          <h2 className={virtualTradeshowStyles.carouselTitle}>
            Customer Story
          </h2>
          <VirtualCarousel
            showIndicator={false}
            className={"virtualCarousel customer"}
          >
            <div className={virtualTradeshowStyles.slideWrapper}>
              <div className={virtualTradeshowStyles.quoteWrapper}>
                <img src={quote}></img>
              </div>
              <p className={virtualTradeshowStyles.comment}>
                Augmented personal experiences like the ones Hashout have
                developed will continue to be a valuable way to reach customers.
                Every business that uses events to market themselves will need a
                platform like ours to get their messaging out there and to reach
                their customers. With Hashout Tech you get a trusted partner who
                has your back.
              </p>
              <p className={virtualTradeshowStyles.comment}>
                They worked so hard to bring our exhibition to life and I can’t
                say enough good things about them. The results are fabulous.
              </p>
              <h4 className={virtualTradeshowStyles.author}>
                Maureen Payne, CMP
              </h4>
              <span className={virtualTradeshowStyles.company}>
                Trade Show Manager - Straumann Group
              </span>
            </div>
          </VirtualCarousel>
        </div>
        <div className={virtualTradeshowStyles.customerBanner}>
          <img src={customerBannerImage}></img>
        </div>
      </div>
      <div className={virtualTradeshowStyles.contactSection} ref={myRef}>
        <div className={virtualTradeshowStyles.circularShape}></div>
        <div className={virtualTradeshowStyles.contactBanner}>
          <h3 className={virtualTradeshowStyles.contactTitle}>
            Want to maximize attendance levels and generate vast numbers of
            leads?
          </h3>
          <span className={virtualTradeshowStyles.contactInfoSub}>
            Let Hashout Tech design and develop your next virtual event
          </span>
        </div>
        <div className={virtualTradeshowStyles.contactFormWrapper}>
          <ContactUsForm url={url} helpText="Message*" page="Virtual tradeshow" section="Bottom"></ContactUsForm>
        </div>
      </div>
    </Layout>
  );
}

export default virtualTradeshow;
